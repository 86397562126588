<style lang="scss" scoped></style>

<template>
	<div>
		<keep-alive :include="$store.state.cps_keepLiveCom">
			<router-view />
		</keep-alive>
		<van-tabbar :active-color="activeColor" v-model="active" fixed v-if="$route.meta.tab">
			<van-tabbar-item name="cpsHome" :to="{ path: '/cps/cpsHome', query: { code: $route.query.code } }" > 
				<span>首页</span>
				<template #icon="props">
					<img :src="props.active?require('@/assets/image/activeHome.png'):require('@/assets/image/home.png')" />
				</template>
			</van-tabbar-item>
			<van-tabbar-item name="cpsProduct" :to="{ path: '/cps/cpsProduct', query: { code: $route.query.code } }" > 
				<span>所有产品</span>
				<template #icon="props">
					<img :src="props.active?require('@/assets/image/activeCategory.png'):require('@/assets/image/category.png')" />
				</template>
			</van-tabbar-item>
			<van-tabbar-item name="cpspFileSearch" :to="{ path: '/cps/cpspFileSearch', query: { code: $route.query.code } }" >
				<span>保单查询</span>
				<template #icon="props">
					<img :src="props.active?require('@/assets/image/activeSearch.png'):require('@/assets/image/shieldSearch.png')" />
				</template>
			</van-tabbar-item>
		</van-tabbar>
	</div>
</template>

<script>
import { Tabbar, TabbarItem } from 'vant';
export default {
	name: 'cps', // tabBar
	components: {
		[Tabbar.name]: Tabbar,
		[TabbarItem.name]: TabbarItem,
	},
	created() {
		this.getPath();
	},
	data() {
		return {
			active: '',
			activeColor: '#0997F7',
		};
	},
	watch: {
		'$route.name'(newVla) {
			this.active = newVla;
		},
		'$store.state.cps_brindInfo.brandColor': {
			handler(newVal) {
				this.activeColor = newVal;
			},
			immediate: true,
			deep: true,
		},
	},
	methods: {
		getPath() {
			this.active = this.$route.name;
		},
	},
};
</script>
